import React from "react";
import SlicesZone from "../../modules/Prismic/SlicesZone";
import GeneralHelmet from "components/molecules/GeneralHelmet/GeneralHelmet";

const Prestation = ({ data }) => {
  const { slices, meta } = data;

  return (
    <>
      <GeneralHelmet meta={meta} />
      {slices && <SlicesZone slices={slices} />}
    </>
  );
};

export default Prestation;
